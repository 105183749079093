class ClockSwitch {
    constructor(hour, switchElement) {
        if (hour >= 7 && hour <= 19) {
            switchElement.setAttribute('class', 'c-switch day');
            switchElement.parentNode.setAttribute('class', 'l-content c-content day');
        } else {
            switchElement.setAttribute('class', 'c-switch night');
            switchElement.parentNode.setAttribute('class', 'l-content c-content night');
        }
    }
}

const hour = new Date().getHours();
const nightSwitch = document.getElementById('switch');

const clockswitch = new ClockSwitch(hour, nightSwitch);


export default clockswitch;
