class SwitchButton {
    constructor(switchElement) {
        switchElement.addEventListener('click', () => {
            if (switchElement.className === 'c-switch night') {
                switchElement.setAttribute('class', 'c-switch day');
                window.setTimeout(() => {
                  switchElement.parentNode.setAttribute('class', 'l-content c-content day');
                }, 300);
            } else {
                switchElement.setAttribute('class', 'c-switch night');
                window.setTimeout(() => {
                  switchElement.parentNode.setAttribute('class', 'l-content c-content night');
                }, 300);
            }
        });
    }
}

const nightSwitch = document.getElementById('switch');
const switchButton = new SwitchButton(nightSwitch);

export default switchButton;
